@import "../../scss/variables";


body {
  background: #eaedf7;
}

.custom-card {
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
}

.main-header-center {
  .form-control {
    border: 1px solid #eceef5;

    &:focus {
      background: transparent;
    }
  }

  .select2-container--default .select2-selection--single {
    border-right: 0;
  }
}

.bg-primary {
  background-color: #00818a !important;

  &::hover,
  &::focus {
    background-color: #00818a !important;
  }
}

.bg-primary-transparent {
  background-color: rgba(29, 81, 235, 0.1);
}

.select2-container--classic {
  .select2-selection--single:focus,
  &.select2-container--open .select2-selection--single,
  .select2-selection--multiple:focus {
    border: 1px solid #00818a;
  }

  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid #00818a;
    }

    .select2-dropdown {
      border-color: #00818a;
    }
  }
}

.main-header-center .search-btn {
  background-color: #00818a;
}

.text-primary {
  color: #00818a !important;

  &:hover,
  :focus {
    color: #00818a !important;
  }
}

.border-primary {
  border-color: #00818a !important;
}

.bd-primary {
  border-color: #00818a;
}

.card-item .card-item-icon.card-icon {
  background: rgba(77, 76, 178, 0.1);
  fill: #00818a;
}

.card-dashboard-calendar .ui-datepicker {
  .ui-datepicker-month {
    color: #00818a;
  }

  .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #00818a;
    border: 1px solid #00818a;
  }
}

#back-to-top {
  background: #00818a;
}

.ckbox span:after {
  background-color: #00818a;
}

.btn-primary {
  color: #fff !important;
  background-color: #00818a !important;
  border-color: #00818a !important;

  &:hover {
    color: #fff;
    background-color: #403fad;
    border-color: #403fad;
  }

  &:focus,
  &.focus {
    background-color: #403fad;
    border-color: #403fad;
    box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #00818a;
    border-color: #00818a;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #4c4bb7;
      border-color: #5554c5;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c4bb7;
  border-color: #5554c5;
}

.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
}

.btn-main-primary {
  color: #fff !important;
  background-color: #00818a !important;
  border-color: #00818a !important;

  &:hover {
    color: #fff;
    background-color: #403fad;
    border-color: #403fad;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px rgba(64, 63, 173, 0.3);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #00818a;
    border-color: #00818a;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #4c4bb7;
      border-color: #5554c5;
    }
  }
}

.show > .btn-main-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c4bb7;
  border-color: #5554c5;
}

.btn-main-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px rgba(64, 63, 173, 0.3);
  }
}

.show > .btn-main-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(64, 63, 173, 0.3);
}

.btn-outline-primary {
  color: #00818a !important;
  border-color: #00818a;

  &:hover {
    color: #fff !important;
    background-color: #00818a;
    border-color: #00818a;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
  }

  &.disabled,
  &:disabled {
    color: #00818a;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff !important;
      background-color: #00818a;
      border-color: #00818a;
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #00818a;
  border-color: #00818a;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(98, 89, 202, 0.2);
}

.btn-light {
  color: #2c3033;
  background-color: #f0f0ff;
  border-color: #f0f0ff;

  &:hover {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
  }

  &.disabled,
  &:disabled {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #2c3033;
      background-color: #dbe0ea;
      border-color: #dbe0ea;
    }
  }
}

.show > .btn-light.dropdown-toggle {
  color: #2c3033;
  background-color: #dbe0ea;
  border-color: #dbe0ea;
}

.btn-light:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.badge-primary {
  color: #fff !important;
  background-color: #00818a;
}

a.badge-primary {
  &:hover {
    color: #fff !important;
    background-color: #00818a;
  }

  &:focus {
    color: #fff !important;
    background-color: #00818a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(12 129 138 / 35%);
  }

  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(12 129 138 / 35%);
  }
}

.badge-light {
  color: #55556b;
  background-color: #f0f0ff;
}

a.badge-light {
  &:hover {
    color: #8f9cc0;
    background-color: #d5d9e4;
  }

  &:focus {
    color: #8f9cc0;
    background-color: #d5d9e4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
  }

  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
  }
}

.main-nav-column .nav-link.active {
  color: #00818a;

  &:hover,
  &:focus {
    color: #00818a;
  }
}

a {
  color: #00818a;

  &:hover {
    color: #00818a;
  }
}

.main-nav-line-chat .nav-link.active,
.main-msg-send {
  color: #00818a !important;
}

.main-calendar {
  .fc-view.fc-agenda-view .fc-day-header.fc-today > a {
    color: #00818a;
  }

  td.fc-today .fc-day-number {
    background-color: #00818a;

    &:hover,
    &:focus {
      background-color: #00818a;
    }
  }
}

.main-avatar {
  background-color: #00818a;
}

.page-item.active .page-link {
  background-color: #00818a;
  border-color: #00818a;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > {
  td:first-child:before,
  th:first-child:before {
    background-color: #00818a;
  }
}

pre {
  background-color: #ecebfb;
}

.flat a {
  &:hover,
  &.active,
  &:hover:after,
  &.active:after {
    background: #00818a !important;
    color: #fff;
  }
}

.nav-pills .nav-link.active {
  background-color: #00818a;
}

.main-nav .nav-link.active {
  color: #00818a;
}

.accordion .card-header a,
.accordion .card-header button {
  color: #00818a;
  background-color: #e1e1f9;
}

.accordion-color .card-header a.collapsed,
.accordion-color .card-header button.collapsed {
  background-color: #00818a !important;
}

.timeline-wrapper-primary {
  .timeline-panel:before,
  .timeline-badge {
    background: #00818a;
  }
}

.br-theme-bars-1to10 .br-widget a {
  &.br-active,
  &.br-selected {
    background-color: #00818a;
  }

  background-color: #f0f2f8;
}

.br-theme-bars-movie .br-widget a {
  &.br-active,
  &.br-selected {
    background-color: #00818a;
  }
}

.br-theme-bars-square .br-widget a {
  &.br-active,
  &.br-selected {
    border: 2px solid #f0f2f8;
    color: #00818a;
  }
}

.br-theme-bars-pill .br-widget a {
  &.br-active,
  &.br-selected {
    background-color: #00818a;
    color: white;
  }
}

.br-theme-bars-horizontal .br-widget a {
  &.br-active,
  &.br-selected {
    background-color: #00818a;
  }
}

.main-content-body-profile .main-nav-line .nav-link.active {
  color: #00818a;
}

// .table-striped tbody tr:nth-of-type(2n + 1) {
//   background-color: #f6f8ff;
// }

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #efefff;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #00818a;
}

.rdiobox input[type="radio"]:checked + span:before {
  border-color: transparent;
  background-color: #00818a;
}

.form-control {
  &:disabled,
  &[readonly] {
   
  }
}

.selectgroup-input:checked + .selectgroup-button {
  border-color: #00818a;
  color: #00818a;
  background: #e5e9f3;
}

.input-group-text {
  background-color: #f6f6ff;
  border: 1px solid #0002;
}

.ui-datepicker {
  .ui-datepicker-header {
    .ui-datepicker-next::before,
    .ui-datepicker-prev::before {
      background: rgba(77, 76, 178, 0.1);
      color: #00818a;
    }
  }

  .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #00818a;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #00818a;
      color: #fff;
    }
  }
}

.irs-bar {
  background-color: #00818a;
}

.irs-from,
.irs-to,
.irs-single {
  color: #fff;
  background: #00818a;
}

.irs-line-mid,
.irs-line-right {
  background-color: #f0f0ff;
}

.irs-min,
.irs-max {
  color: #78769a;
  background: #f2f1ff;
}

.select2-container--default {
  &.select2-container--disabled .select2-selection--single {
    background-color: #f2f1ff;
  }

  .select2-selection--single {
    background-color: #fff;
    border: 1px solid #0002;
  }
}

.ff_fileupload_wrap .ff_fileupload_dropzone {
  border: 2px dashed #dcd9f5;
  background-color: #f2f1ff;
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: #f8f9ff !important;
    color: #00818a;
  }

  .select2-results__option[aria-selected="true"] {
    background-color: #f1f2f9;
  }
}

.wizard > .steps {
  .current a {
    .number,
    &:hover .number,
    &:active .number {
      background-color: #00818a;
      color: #fff;
    }
  }

  a {
    .number,
    &:hover .number,
    &:active .number {
      color: #8f9cc0;
      background-color: #f0f0ff;
    }
  }
}

.profile-cover__info .nav strong,
.profile-tab .main-nav-line .nav-link.active {
  color: #00818a;
}

.pricing-tabs .nav-price li .active {
  background: #ececfc;
  color: #00818a;
}

.table-hover tbody tr:hover {
  background-color: #f8f8ff;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: #eaeafb;
}

.bg-gray-200 {
  background-color: #d0d0ec;
}

.bg-gray-300 {
  background-color: #babade;
}

.bg-gray-400 {
  background-color: #8f8fb7;
}

.bg-gray-500 {
  background-color: #717196;
}

.bg-gray-600 {
  background-color: #4f4f71;
}

.bg-gray-700 {
  background-color: #3c3c5d;
}

.bg-gray-800 {
  background-color: #262644 !important;
}

.bg-gray-900 {
  background-color: #1b1b33;
}

.signpages .details:before {
  background: rgba(77, 76, 178, 0.9);
}

.construction:before {
  background: rgba(77, 76, 178, 0.96);
}

.tag-primary,
.progress-bar {
  background-color: #00818a;
}

.breadcrumb {
  background-color: #f0f0ff;
}

.bg-primary .main-nav .nav-link.active,
.bg-secondary .main-nav .nav-link.active {
  color: #fff;
}

.popover-header {
  background-color: #00818a !important;
  color: #fff;
}

.lds-heart div {
  &:after,
  &:before {
    background: #00818a;
  }
}

.lds-ripple div {
  border: 4px solid #00818a;
}

.lds-spinner div:after {
  background: #00818a;
}

.select2-container--default .select2-selection--multiple {
  .select2-selection__choice {
    background-color: #e0e2f7;
    border: 1px solid #e0e2f7;
    color: #1d212f;
  }

  .select2-selection__choice__remove {
    color: #1d212f;
  }
}

.lds-circle > div {
  background: #00818a;
}

.lds-dual-ring:after {
  border: 5px solid #00818a;
  border-color: #00818a transparent #00818a transparent;
}

.lds-facebook div {
  background: #00818a;
}

.main-navbar {
  .nav-item.active .nav-link {
    color: #00818a;

    &.with-sub::after {
      color: #00818a;
    }
  }

  .nav-sub-item.active > .nav-sub-link {
    color: #00818a;

    &:before {
      color: #00818a;
    }
  }

  .nav-sub-link {
    &:hover,
    &:focus {
      color: #00818a;
    }
  }
}

@media (min-width: 992px) {
  .main-navbar {
    .nav-sub-link {
      &:hover,
      &:focus {
        color: #00818a;
      }
    }

    &.hor-menu .nav-sub-item.active a.active {
      color: #00818a;
    }

    .nav-sub-item.active > .nav-sub-link {
      color: #00818a;

      &:before {
        color: #00818a;
      }
    }
  }

}

@media (max-width: 991px) {
  .main-navbar {
    // &.hor-menu .nav-sub-item.active a {
    //   color: #ffffff;
    // }
    &.hor-menu .nav-sub-item.active a .nav-sub .nav-sub-link {
        color: #bdbdd0;
      }

    .nav-sub-item > .nav-sub-link {
      color: #ffffff;

      &:before {
        color: #ffffff;
      }
    }
  }
}

.main-form-search {
  .btn,
  .sp-container button {
    background-color: #00818a;
  }
}

.nav-tabs .nav-link {
  &:hover,
  &:focus {
    background-color: #00818a !important;
    color: #ffffff !important;
  }
}

.lds-heart div {
  background: #00818a;
}

.accordion .card-header a.collapsed,
.accordion .card-header button.collapsed {
  &:hover,
  &:focus {
    background-color: #00818a;
    color: #fff;
  }
}

.main-sidebar-body li.active {
  .sidemenu-label,
  i {
    color: #00818a;
  }
}

.light-horizontal .main-navbar {
  &.hor-menu .nav-item {
    &.active .nav-link,
    &:hover .nav-link {
      color: #00818a;
    }
  }
  &.hor-menu .nav-item.active .nav-link {
    &.with-sub::after {
      color: #00818a !important;
    }
  }
  .nav-link,
  .nav-item:hover .nav-link.with-sub::after {
    color: #7b7ba2;
  }
}
@media (max-width:992px){
  .color-horizontal .main-navbar {
    .nav-sub-link {
      &:hover,
      &:focus {
        color: $white;
      }
    }

    &.hor-menu .nav-sub-item.active a.active {
      color: $white;
    }
  }
}

.main-navbar .nav-sub-item>.nav-sub-link:before{
  color: #bdbdd0;
}
.main-sidebar-body {
  .nav-sub-link {
    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .nav-sub .nav-sub-item .nav-sub-link.active:before {
    color: #fff;
    opacity: 0.9;
  }

  li.active .sidemenu-icon {
    background: #00818a;
  }
}

.project .deadline {
  background: #eaedf7;
}

.project-status.primary:before {
  border-left: 3px solid #00818a;
}

.badge-primary-light {
  background-color: rgba(98, 89, 202, 0.16);
  color: #6259c9;
}

.badge-warning-light {
  background-color: #fdf5e2;
  color: #ebb322;
}

.badge-danger-light {
  background-color: #ffe7e8;
  color: #e03c47;
}

.peity {
  &:before {
    content: "5%";
    position: absolute;
  }

  position: relative;
}

.list-card {
  background: #f6f6fb;
}

.forth.circle .chart-circle-value.circle-style {
  border: 2px dotted #aba5f4;
  background: #fcfcff;
}

.color-leftmenu {
  .main-sidebar-sticky {
    background: #00818a;
  }
  .main-body.leftmenu .sidemenu-logo {
    background: #00818a;
  }

  .main-sidebar-body {
    .sub-txt {
      color: #9d96ef;
    }

    .nav-label {
      color: rgba(209, 213, 230, 0.4);
    }
  }
}

.dark-theme.light-leftmenu .second-sidemenu {
  .slide-item.active,
  .menu .slide-item.active:before {
    color: #00818a;
  }
}

.resp-tabs-list li {
  &.resp-tab-item.active .side-menu__icon,
  &.resp-tab-active .side-menu__icon {
    background: #00818a;
  }
}

.crypto-btc-usd .nav-link.active {
  background-color: #00818a !important;
  color: #fff !important;
}

.irs-slider:before {
  background-color: #00818a !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00818a;
  background-color: #00818a;
}

.sweet-alert button {
  background-color: #00818a !important;
}

.alert-message {
  border: 1px solid rgb(84, 75, 175) !important;
  border-radius: 3px !important;
  background: #5a51b9 !important;
  padding: 1rem;
}
.alert-icons {
  height: 70px;
  width: 70px;
  fill: #fff;
}

// .alert-message {	
//   border: 1px solid rgba(0, 0, 0, 0.12);	
//   border-radius: 3px;	
//   background: rgba(0, 0, 0, 0.12);	
//   padding: 1rem;	
// }



.popover-primary {
  background-color: #00818a;
  border-color: #00818a;
}

.popover-secondary {
  background-color: #f1388b;
  border-color: #f1388b;
}

// .bs-popover-top > .arrow::after,
// .bs-popover-auto[x-placement^="top"] > .arrow::after {
//   bottom: 1px;
//   border-width: 0.5rem 0.5rem 0;
//   border-top-color: #ffffff;
// }

.popover-primary {	
  &.bs-popover-top > .arrow::after,	
  &.bs-popover-auto[x-placement^="top"] > .arrow::after,	
  &.bs-popover-top > .arrow::before,	
  &.bs-popover-auto[x-placement^="top"] > .arrow::before {	
    border-top-color: #00818a;	
  }	
}
